/* You can add global styles to this file, and also import other style files */
@import "~ag-grid-community/styles//ag-grid.css";
@import "~ag-grid-community/styles//ag-theme-quartz.css";

@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
/* for icon */
@import "node_modules/@fortawesome/fontawesome-free/css/all.css";
@import url('./variables.css');


@font-face {
    font-family: 'Graphik';
    src: url('./assets/fonts/GraphikLight.otf') format('opentype');
    font-weight: 300;
  }
  
  @font-face {
    font-family: 'Graphik';
    src: url('./assets/fonts/GraphikRegular.otf') format('opentype');
    font-weight: 400; 
  }
  
  @font-face {
    font-family: 'Graphik';
    src: url('./assets/fonts/GraphikMedium.otf') format('opentype');
    font-weight: 500; 
  }

/* FONT STYLE END */

/* ------------------------------------------------------------- */

body {
    font-family: 'Graphik', sans-serif;
    color: var(--white);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.28px;
    /* text-transform: uppercase; */
}

/* for cancel btn */
.btn-primary-color-outline {
    color: var(--dark-red);
    display: flex;
    height: 40px;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    border: 1px solid var(--dark-red);
}

.btn-primary-color-outline:hover {
    border-radius: 100px;
    border: 1px solid var(--dark-red);
    box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.12);
    color: var(--Color-Accent-Accent-1, #AE0005);
}

.btn-primary-color-outline:focus {
    border-radius: 100px;
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.12);
    border: 1px solid var(--dark-red);
    color: var(--dark-red);
}

/* BUTTON STYLE END */

.p-dropdown-items {
    padding: 0 !important;
    margin-bottom: 0 !important;
}

.sd-action-bar {
    display: none;
}

.required {
    color: red;
    margin-left: 1px;
}

.data-not-found {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    padding: 12px;
    margin-top: 16px;
    border-radius: 50px;
    letter-spacing: 0.55px;
    text-align: center;
    color: var(--gray-01);
    background-color: var(--gray-03);
}

.svc-creator__banner {
    display: none !important;
}

.remove-dropdown-border .p-dropdown:not(.p-disabled).p-focus {
    box-shadow: none !important;
    border: 1px solid #AE0005;
}