@import url('./variables.css');

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--black-01);
  margin: 0;
}

h1 {
  font-size: 96px;
  font-weight: 300;
}

h2 {
  font-size: 60px;
  font-weight: 300;
}

h3 {
  font-size: 48px;
  font-weight: 400;
}

h4 {
  font-size: 22px;
  font-weight: 400;
}

h5 {
  font-size: 20px;
  font-weight: 400;
}

h6 {
  font-size: 18px;
  font-weight: 500;
}

p[role-type="subtitle1"] {
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  color: var(--black);
}

p[role-type="subtitle2"] {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
  color: var(--gray-04);
}

p[role-type="body1"] {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
}

input, .form-select, input[type="text"], input[type="password"] {
  display: flex;
  align-items: center;
  font-size: 16px;
  border-radius: 8px;
  height: 40px;
  border: 1px solid var(--gray-01);
  line-height: 22px;
}

input:focus, .form-select:focus, textarea:focus, input[type="text"]:focus, .form-control:focus {
  border: 1px solid var(--primary);
  box-shadow: none;
  outline: none;
}

textarea {
  border: 1px solid var(--gray-01);
  height: 48px;
}

.p-datatable-header {
  .p-inputtext.p-component {
    border-radius: 50px;
  }
}

.input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) {
  border-radius: 8px;
}

input::placeholder, .form-control::placeholder {
  color: var(--gray-02);
}

input[type="text"]:disabled .form-control:disabled {
  color: var(--gray-01);
}

input[type='radio']:hover {
  box-shadow: none;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  background-color: var(--primary-04);
}

.nav-tabs {
  .nav-link {
    color: var(--gray-02);
    &.active {
      color: var(--primary);
    }
  }
}

.common-table td {
  color: var(--black-01);
}


/* BUTTON STYLE START */
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 28px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.28px;
  text-transform: uppercase;
  gap: 10px;
  border-radius: 40px;
  color: var(--brown-03);
  white-space: nowrap;
}

.btn-primary-type {
  background: var(--accent);
}

.btn-primary-type:disabled {
  border-radius: 40px;
  background: var(--gray-03);
  color: #495057;
  border: 0;
}

.btn-primary-type:disabled:hover {
  background: var(--gray-03);
  box-shadow: none;
  color: var(--black-01);
}

.btn-primary-type {
  &:hover, &:focus, &:active,
  &:not(.btn-check) + .btn:active,
  &.btn-primary-type:first-child:active,
  &:active, &:not(.btn-check) + .btn:active {
    background-color: var(--accent);
    box-shadow: 2px 6px 4px 0 rgba(0, 0, 0, 0.12);
    color: var(--brown-03);
    border: 1px solid transparent; 
  }
}

.btn-primary-type:active, :not(.btn-check) + .btn:active {
  background-color: var(--accent) !important;
}

.btn-secondary-type {
  border: 1px solid var(--gray-01);
  background-color: var(--white);
  color: var(--black-01);
}

.btn-secondary-type {
  &:hover, &:focus, &:active,
  &:not(.btn-check) + .btn:active,
  &:first-child:active {
    border: 1px solid var(--gray-01);
    background-color: var(--white) !important;
    color: var(--black-01);
    box-shadow: 2px 6px 4px 0px rgba(0, 0, 0, 0.12);
  }
}

.btn-secondary:active, :not(.btn-check) + .btn:active {
  background-color: var(--white) !important;
  color: var(--brown-03);
}

.btn-default {
  color: var(--brown-01);
  padding: 0;
}

.btn-disabled {
  background-color: var(--gray-03);
  color: var(--gray-01);
}

.cta-btn {
  background-color: var(--primary);
  color: var(--white);
  border: 0;
  &:hover, &:active,
  &.btn:first-child:active {
    background-color: var(--primary);
    color: var(--white);
    box-shadow: 2px 6px 4px 0px rgba(0, 0, 0, 0.12);
  }
}

.p-datatable-table .btn-type-edit {
  border: 1px solid var(--gray-01);
  padding: 4px 14px;
  color: var(--gray-02);
}

.p-datatable-table .btn-type-edit:hover {
  box-shadow: 2px 6px 4px 0px rgba(0, 0, 0, 0.12);
}

.p-datatable-table .btn-type-edit:active {
  background-color: var(--white);
  border: 1px solid var(--gray-01);
  padding: 4px 14px;
  color: var(--gray-02);
}

.btn-type-delete {
  padding: 4px 14px;
}

.p-datatable-wrapper {
  border: 1px solid var(--primary);
  border-radius: 10px;
}

.p-datatable .p-datatable-thead > tr > th {
  font-weight: 500;
}

.p-datatable-thead {
  border-bottom: 1px solid var(--primary);
}

.p-datatable-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0;
  padding: 10px 0;
}

.p-datatable-header, .p-datatable-thead {
  background: transparent;
}

.p-datatable-table th {
  font-size: 16px;
  text-align: center;
  color: var(--primary);
  padding: 20px;
  background: var(--white);
}

.p-datatable-table td {
  font-size: 14px;
  text-align: center;
  padding: 22px;
}

.p-datatable-table td img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.p-datatable-table td .bi-eye-fill, .bi-geo-alt {
  font-size: 20px;
}

.p-datatable-tbody tr {
  border-bottom: 1px solid var(--gray-03);
}

.p-datatable-table .bi-pencil, .bi-trash3 {
  color: var(--black);
  font-size: 18px;
}

.bi-pencil {
  margin-right: 10px;
}

.p-paginator {
  background: transparent;
}

.p-paginator, .p-paginator-element {
  font-size: 12px;
}

.p-paginator-page, .p-paginator-first, .p-paginator-last {
  background: var(--primary-03);
}

.p-paginator-page, .p-paginator-first, .p-paginator-last, .p-paginator-next, .p-paginator-prev {
  min-width: 2rem;
  height: 2.5rem;
  border-radius: 10px;
  margin: 4px;
}

.p-paginator-next, .p-paginator-prev {
  background: var(--primary-04); 
}

.p-highlight {
  background: var(--primary);
  color: var(--brown-02);
}

.p-paginator-page:hover, .p-highlight:hover {
  opacity: .8;
}

.p-paginator-rpp-options {
  display: none;
}

.p-element .p-paginator {
  justify-content: end;
  padding: 10px 0 60px 0;
}

.p-dropdown-label {
  display: flex;
  align-items: center;
  font-size:14px;
}

.p-icon-wrapper svg {
  width: 12px;
}

.text-danger {
  margin-top: 8px;
}

.survey-audit-container .text-danger {
  margin: 0;
}

i:hover {
  cursor: pointer;
}

.ng-star-inserted section {
  padding: 24px;
}

.content-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.p-float-label textarea ~ label {
  top: 1.5rem;
}

form .p-float-label textarea:focus ~ label, textarea.p-filled ~ label {
  top: -0.75rem;
}

.modal-body .p-input-icon-left > i:first-of-type {
  left: 1rem;
}

.p-datatable-header .p-input-icon-left {
  padding: 0;
}

.table > :not(caption) > * > * {
  padding: 16px;
}

.sideBarUst {
  padding: 9px 0;
}

.nav-item {
  padding: 5px 10px;
  margin-top: 8px;
}

.nav-item .icons {
  color: var(--gray-02);
  font-size: 28px;
}

.survey-form-container .p-float-label input, .form-select, textarea, input[type="text"], input[type="password"].p-inputtext {
  font-size: 16px;
}

.survey-form-container .p-float-label input, textarea {
  width: 50%;
}

footer {
  display: flex;
  align-items: center;
  justify-content: end;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 10;
  width: calc(100% - 100px);
  background-color: var(--surface);
  padding: 12px 10px;
  letter-spacing: .5px;
}

footer span {
  color: #666666;
  font-size: 14px;
  font-weight: 600;
  opacity: .80;
}

.add-new-user-container {
  background: var(--white);
  padding: 12px 24px;
  margin-bottom: 16px;
  p {
    color: var(--gray-02);
    line-height: 18px;
  }
}

.tab-content > .tab-pane {
  overflow: auto;
}

.add-new-user-container .tab-content .tab-pane {
  overflow: hidden;
}

.add-new-user-container .p-overlay.p-component {
  top: -150px !important;
}

.drop-area .btn p {
  display: inline-block;
  margin-left: 16px;
}

.drop-icon i {
  color: var(--gray-02);
  font-size: 3rem;
}

.survey-audit-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.show-details-data {
  flex-grow: 1;
}

.show-details-data .survey-audit-container p {
  margin: 0 10px 0 0;
  font-size: 14px;
  color: var(--black-01);
}

.survey-audit-container strong {
  text-align: right;
}

.survey-dashboard-container .p-datatable-table th {
  padding: 12px; 
}

.drop-area {
  position: relative;
  border: 3px dashed #aaa;
  padding: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  min-height: 96px;
  flex-direction: column;
  gap: 10px;
  cursor: pointer;
}

.form-control[type=file] {
  height: auto;
}

survey-creator, .svc-creator {
  height: 100vh;
}

.survey-dashboard-container .p-dropdown {
  width: 160px;
  border: 1px solid var(--gray-01);
}

.filter-Box p-dropdown {
  margin-right: 8px;
}

.sd-element__title .sd-element__num {
  font-size: 16px;
}

.login-container {
  background-color: var(--surface);
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 56px;
}

.logo img {
  max-width: 100%;
  height: auto;
}

.scrollable-column {
  overflow-y: auto;
  height: 75vh;
}

.survey-sme-container .dropdown-list .filter-textbox input[type="text"], .survey-block-container .dropdown-list .filter-textbox input[type="text"] {
  border: 1px solid  var(--gray-01) !important;
}

.funnel-button, .download-button {
  color: var(--white);
  background: var(--primary);
  padding: 8px 10px;
  margin-left: 10px;
  border: 0;
  border-radius: 50%;
}

.p-toast {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.p-toast-message-text {
  display: flex;
  justify-content: center;
  line-height: 32px;
}

.p-toast-detail {
  margin: 0;
}

.btn-close:focus {
  box-shadow: none;
}

.form-check .form-check-input {
  margin-left: -1.3em;
}

.spg-search-editor_container {
  height: 56px;
  width: 100%;
}

.spg-dropdown_select-wrapper .sd-dropdown__value {
  height: 48px;
}

.sd-dropdown, .spg-table__cell:not(.spg-table__cell--detail-panel) .spg-dropdown {
  height: 74px;
}

.sd-dropdown__value {
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.sd-dropdown__filter-string-input {
  padding: 0 10px;
}

/* CheckBox && Radio */
input[type='radio'],
input[type='checkbox'] {
  color: var(--primary);
  position: relative;
}

.multiselect-item-checkbox input[type=checkbox]+div:before {
  color: var(--dark-red) !important;
  border: 2px solid var(--dark-red) !important;
}

.multiselect-item-checkbox input[type=checkbox]:checked+div:before {
  background: var(--dark-red) !important;
  box-shadow: 0 0 0 2px var(--dark-red);
}

input[type='radio']:after,
input[type='checkbox']:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.form-switch input[role='switch'] {
  color: var(--dark-red) !important;
}

.form-check-input:checked {
  background-color: var(--primary);
  border-color: var(--primary);
}

.status-badge span {
  padding: 11px 16px;
  border-radius: 50px;
  white-space: nowrap;
}

.text-success {
  color: var(--success) !important;
}

.text-danger {
  color: var(--accent-01) !important;
}

.bg-success {
  background: var(--success-01) !important;
}

.bg-danger {
  background: var(--accent-02) !important;
}

.shadow-md {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.modal-header {
  background-color: var(--primary-04);
}
.modal-body{
  background-color: var(--white);
}
.modal-dialog-scrollable {
  p {
    line-height: 18px;
    font-weight: 400;
    color: var(--gray-02);
  }
  .form-check {
    display: flex;
    align-items: center;
    gap: 12px;
  }
}

.form-check-input {
  width: 20px;
  height: 20px;
  margin: 0;
  &:focus {
    box-shadow: none;
  }
}

.text-gray {
  color: var(--gray-04);
}

@media (max-width: 1399px) {
  .survey-dashboard-container .p-datatable-table th {
    padding: 8px;
  }
  .sv-action-bar {
    margin: 0 24px;
  }
  .sd-dropdown--empty:not(.sd-input--disabled) .sd-dropdown__value {
    height: 54px;
  }
}

@media (max-width: 1199px) {
  h5 {
    font-size: 20px;
  }
  p[role-type="subtitle1"] {
    font-size: 18px;
  }
  .btn {
    padding: 8px 16px;
  }
  .offcanvas.offcanvas-end {
    left: 0;
    transform: translateX(-100%);
  }
  .offcanvas.show:not(.hiding) {
    transform: none;
  }
  .navbar-toggler {
    font-size: 16px;
    border: 0;
    padding-top: 0;
  }
  .navbar-toggler:focus {
    box-shadow: none;
  }
  .nav-link {
    margin-bottom: 14px;
  }
  .nav-item {
    margin: 0;
    padding: 0 8px;
  }
  .sideBarUst {
    padding: 6px 0;
  }
  .sd-dropdown input[readonly] {
    height: 100%;
  }
  footer {
    width: 100%;
  }
  .survey-dashboard-container .p-datatable-table th {
    padding: 12px;
  }
}

@media (max-width: 991px) {
  h6 {
    font-size: 18px;
  }
  .btn {
    padding: 6px 16px;
  }
  .p-datatable-table th {
    padding: 14px;
    font-size: 14px;
  }
  .p-datatable-table td {
    padding: 14px;
  }
  .ng-star-inserted section, .add-new-user-container, .sd-root-modern--mobile .sd-body.sd-body--responsive, .sd-root-modern--mobile .sd-body.sd-body--static {
    padding: 16px;
  }
  .nav-item .icons {
    font-size: 22px;
  }
  .survey-form-container .p-float-label input, textarea {
    width: 100%;
  }
  .modal-dialog {
    padding: 0 12px;
    max-width: 100%;
  }
  .data-not-found {
    font-size: 14px;
  }
  .drop-area {
    min-height: 80px;
  }
  .drop-icon i {
    font-size: 2.5rem;
  }
  .drop-area .btn p {
    font-size: 14px;
  }
  .sd-root-modern .sd-container-modern__title .sd-header__text h3, .sd-page .sd-page__title {
    font-size: 20px;
  }
  .sv-popup__body-header {
    font-size: 18px;
  }
  .sl-row--multiple .sl-question {
    margin: 0;
  }
  .svc-page__content {
    padding: 0;
  }
  .svc-page__footer {
    margin: 0 22px;
  }
  .sd-tagbox.sd-tagbox--empty .sd-tagbox__hint {
    height: 100%;
  }
  .svc-text--normal, .sd-root-modern .sd-container-modern__title .sd-description, .spg-question__title, .spg-checkbox__caption, .sv-button-group__item-caption, .sd-dropdown__value, .sd-root-modern input, .form-select, textarea, input[type="text"], input[type="password"], .sv-string-viewer, .spg-table__cell:not(.spg-table__cell--detail-panel) .spg-input, .svc-logic-paneldynamic .svc-logic-operator, .spg-comment {
    font-size: 14px;
  }
  .sv-popup--modal>.sv-popup__container>.sv-popup__shadow>.sv-popup__body-content {
    padding: 20px;
  }
  .sd-btn {
    padding: 10px 20px;
    font-size: 14px;
  }
  .svc-designer__placeholder-container {
    text-align: center;
  }
  .spg-search-editor_input, .sv-popup--dropdown-overlay .sv-list__input {
    padding-left: 40px;
  }
  .spg-search-editor_search-icon, .sv-list__filter-icon .sv-svg-icon {
    position: absolute;
    left: 24px;
  }
  .login-container section {
    padding: 0;
  }
  .download-button {
    padding: 4px 6px;
  }
}

@media (max-width: 767px) {
  .btn {
    padding: 10px 20px;
    font-size: 12px;
  }
  h4,h5 {
      font-size: 18px;
  }
  h6 {
      font-size: 16px;
  }
  p[role-type="subtitle1"] {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .nav-item .icons {
    font-size: 20px;
  }
  .content-heading {
    margin-bottom: 16px;
  }
  .p-datatable-header {
    flex-direction: column;
  }
  .table > :not(caption) > * > * {
    padding: 0;
  }
  .modal-body .p-input-icon-left > i:first-of-type {
    left: 0.9rem;
  }
  .modal-dialog {
    margin: 20px 0 0 0;
  }
  .modal-header, .modal-footer {
    padding: 10px 14px;
  }
  .modal-title {
    margin-right: 10px;
  }
  .modal-footer > * {
    margin: 0;
  }
  .btn .bi-plus-circle {
    display: none;
  }
  .survey-form-container .p-float-label {
    margin-bottom: 16px;
  }
  .search-bar, .p-input-icon-left, .p-inputtext {
    width: 100%;
  }
  .nav-tabs .nav-link {
    width: 50%;
  }
  .common-table > :not(caption) > * > * {
    padding: 10px;
  }
  .p-element .p-paginator {
    padding: 10px 0 34px 0;
  }
  .svc-tab-designer .sd-container-modern.sd-container-modern--static {
    min-width: 100%;
  }
  .svc-creator--mobile .svc-tab-designer .sd-title.sd-container-modern__title {
    flex-direction: row;
  }
  .update-survey-container .text-info {
    margin: 0;
    font-size: 11px;
  }
  .heading-btn-container .btn {
    padding: 0;
  }
  .heading-btn-container p {
    font-size: 14px;
    margin: 0;
  }
  .drop-area .btn p {
    display: block;
    margin: 5px 0 0 0;
  }
  .drop-icon i {
    font-size: 2rem;
  }
  .survey-dashboard-container .p-dropdown {
    width: 110px;
  }
  .filter-Box p-dropdown:last-child {
    margin: 0;
  }
  .scrollable-column {
    overflow: hidden;
    height: 100%;
  }
  .bi-download, .bi-funnel {
    font-size: 14px;
  }
}
