:root {
  --surface: #f7f7f7;
  --white: #ffffff;
  --black: #000000;
  --primary: #3F41D1;
  --primary-03: #CFD5F1; 
  --primary-04: #E1E8F5;
  --accent: #E697AB;
  --accent-01: #E6393E;
  --accent-02: #FAD7D8;
  --dark-red: #AE0005;
  --red-01: #FF5B61;
  --gray-01: #A3A3A3;
  --gray-02: #666666;
  --gray-03: #E0E0E0;
  --gray-04: #888888;
  --black-01: #212121;
  --brown-01: #864f20;
  --brown-02: #F2E2BE;
  --brown-03: #310000;
  --yellow-01: #ffcc00;
  --success: #06C270;
  --success-01: #CDF3E2;
}
